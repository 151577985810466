// Here you can add other styles
.sidebar{
    background-color: #00784c;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background:#E0EEE9;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(112, 112, 112); 
  }
  .sidebar-nav .nav-link {
    color: rgb(255 255 255 / 82%);
  }

.bordergallery{
  border-left-style: dotted;
  border-color: rgb(152, 152, 153);
  padding: 10px;
}
.righthorizontal {
  display: flex;
  flex-direction: row;
}
.logout-cursor{
  cursor: pointer;
}